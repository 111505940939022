<template>
  <Loader v-if="processing">Arbejder...</Loader>
  <div class="w-full min-h-screen flex flex-col items-center justify-start">
    <figure class="w-3/4 md:w-1/3 my-20">
      <img class="w-full" alt="Farmnet logo" src="@/assets/logo.svg" />
    </figure>
    <p
      class=" w-3/4 md:w-1/3 mb-4 text-center"
      :class="[complete ? 'text-green' : 'text-yellow']"
    >
      {{ message }}
    </p>
    <div
      class="w-full flex md:flex-col md:items-center justify-center flex-wrap"
    >
      <Input
        icon="fa-envelope"
        type="text"
        class="w-3/4 md:w-1/3 mb-3"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        placeholder="Indtast din e-mail . . ."
        v-model="email"
      />
    </div>
    <Button
      v-if="!complete"
      label="Send ny kode"
      class="mt-8 w-3/4 md:w-1/3 bg-gradient-to-r
    from-yellow-dark to-yellow"
      @click="resetPassword"
    />
    <router-link class="mt-8 w-3/4 md:w-1/3" :to="{ name: 'Login' }">
      <Button
        v-if="complete"
        icon="fa-arrow-left"
        label="Gå tilbage til login"
        class="w-full bg-gradient-to-r from-yellow-dark to-yellow"
      />
    </router-link>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import Input from "@/components/Input";
import { ref } from "vue";
import firebase from "firebase/app";
import "firebase/functions";
import { systemState } from "@/global/services/systemState";

export default {
  name: "page-reset-password",
  components: {
    Button,
    Input,
    Loader
  },
  setup() {
    const state = {
      email: ref(""),
      message: ref(
        "Her kan du anmode om at få en ny kode sendt til din e-mail."
      ),
      complete: ref(false),
      processing: ref(false)
    };

    document.addEventListener("processing", event => {
      state.processing.value = event.detail;
    });

    const resetPassword = () => {
      systemState.startProcessing();
      const func = firebase.functions().httpsCallable("resetPassword");
      func({
        email: state.email.value
      })
        .then(() => {
          systemState.stopProcessing();
          state.message.value =
            "En kode er nu sendt til din mail. Du kan logge på når du har modtaget den.";
          // Read result of the Cloud Function.
          state.complete.value = true;
        })
        .catch(error => {
          systemState.stopProcessing();
          console.log(error);
        });
    };

    return { ...state, resetPassword };
  }
};
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}
</style>
